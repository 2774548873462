import React from 'react';

import './index.scss';

class AlertBox extends React.Component {
  render() {
    return (
      <div id={this.props.id} className={`alert-box ${this.props.className}`}>
        <div className="content-alert">
          <div className="txt-content">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default AlertBox;
