import React from 'react';

export const HangingMary = ({ symbol, text, className, ...props }) => {
  return (
    <dd {...props} className={`hanging-mary ${className}`}>
      <dt dangerouslySetInnerHTML={{ __html: symbol }} />
      <dl dangerouslySetInnerHTML={{ __html: text }} />
    </dd>
  );
};
