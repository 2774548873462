import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import ActiveLinkNew from '../ActiveLinkNew';
import './brand-cta.scss';

const BrandCTA = props => {
  return (
    <div className="brand-call-out-container">
      <Row center="xs">
        <Col xs={12}>
          <ActiveLinkNew
            ExternalLink={props.ExternalLink}
            EnableActiveClass={false}
            to={props.href}
            EventObject={{
              category: 'Brand Call to Action',
              action: 'Click',
              label: props.LinkCaption,
            }}
            className="call-out-copy"
            {...props}
          >
            {props.children}
          </ActiveLinkNew>
        </Col>
      </Row>
    </div>
  );
};

export default BrandCTA;
