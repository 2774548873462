import React from 'react';

import './index.scss';

const ContentBlock = ({ children, className, id }) => (
  <div id={id} className={`content-block ${className}`}>
    {children}
  </div>
);

export default ContentBlock;
