import React, { Component } from 'react';
import cx from 'classnames';

import './index.scss';

class ColorBlock extends Component {
  render() {
    return (
      <div
        id={this.props.id}
        className={cx({
          'color-block': true,
          'color-block-blue': this.props.blue,
          'color-block-white': this.props.white,
          'color-block-gray': this.props.gray,
          'color-block-lightorange': this.props.lightorange,
          'color-block-pt': this.props.pt,
          'color-block-dkblue': this.props.dkblue,
          'color-block-orangeborder': this.props.orangeborder,

          [this.props.className]: this.props.className,
        })}
      >
        {this.props.children}
      </div>
    );
  }
}

export default ColorBlock;
