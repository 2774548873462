import React, { Component } from 'react';

class ExternalLink extends Component {
  handleClick = () => {
    const isGA = typeof window.ga !== 'undefined';

    if (this.props.gaTracking && isGA) {
      window.ga(
        'send',
        'event',
        this.props.gaTracking.category,
        this.props.gaTracking.action,
        this.props.gaTracking.label,
      );
    }

    if (this.props.deadLink) {
      return;
    }
    window.open(this.props.href, '_blank');
  };

  render() {
    return (
      <a
        href="javascript:void(0);"
        ref={lnk => {
          this.navLink = lnk;
        }}
        data-url={this.props.href}
        className={this.props.className}
        onClick={this.handleClick}
      >
        {this.props.children}
      </a>
    );
  }
}

export default ExternalLink;
