import React, { useContext, useEffect } from 'react';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';
import { Grid, Row, Col } from 'react-flexbox-grid';

import SourceEmitter from 'libs/emitter';
import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';
import ContentBlock from 'components/Content/ContentBlock';
//import HeadlineBar from 'components/Content/HeadlineBar';
import ColorBlock from 'components/Content/ColorBlock';
import BrandCTA from 'components/BrandCta';
import AlertBox from 'components/Content/AlertBox';
import { HangingMary } from 'components/Content/HangingMary';
import ExternalLink from 'components/ExternalLink';

import imgTick from '../../assets/images/table-yellow-tick.png';
import bottle1 from '../../assets/images/bariactiv-bottle1.png';
import bottle2 from '../../assets/images/bariactiv-bottle2.png';
import logoAsmbs from '../../assets/images/asmbs-logo.png';
import imgSmallBottle from '../../assets/images/small-nasal-spray-bottle.png';
import imgBottle1 from '../../assets/images/bottle-icon1.png';
import imgBottle2 from '../../assets/images/bottle-icon2.png';
import NDPatientBariactivHeroMobile from '../../assets/images/ND-patient-bariactivsupplements-mobile.png';

import NDfactsPDF1 from '../../../static/pdfs/NS-05461_Nutrition_Direct_Tablet_BariActiv_Leave_Behind_-_Digital__1_.pdf';
import NDchewablesPDF from '../../../static/pdfs/NS-05460_Nutrition_Direct_Chewable_BariActiv_Leave_Behind_-_Digital_.pdf';

import './bariactiv.scss';

let Subscription = null;

const calciumTracking = {
  category: 'URL-NutritionFacts',
  action: 'clicked on URL-NutritionFacts',
  label: 'Download Calcium Chew Facts',
};

const multiChewTracking = {
  category: 'URL-NutritionFacts',
  action: 'clicked on URL-NutritionFacts',
  label: 'Download Multi Chew Facts',
};

const ironChewTracking = {
  category: 'URL-NutritionFacts',
  action: 'clicked on URL-NutritionFacts',
  label: 'Download Iron Chew Facts',
};

const calciumChewTracking = {
  category: 'URL-NutritionFacts',
  action: 'clicked on URL-NutritionFacts',
  label: 'Download Calcium Tab Facts',
};

const multiTabTracking = {
  category: 'URL-NutritionFacts',
  action: 'clicked on URL-NutritionFacts',
  label: 'Download Multi Tab Facts',
};

const ironTabTracking = {
  category: 'URL-NutritionFacts',
  action: 'clicked on URL-NutritionFacts',
  label: 'Download Iron Tab Facts',
};

const bariactivPage = () => {
  const { appConfigs } = useContext(appContext);

  const indication = appConfigs?.siteOptions?.INDICATIONS?.nutritionPatient;
  // const metaData = appConfigs?.metaData?.nutritionPatient;

  const brandNames = {
    nascobal: appConfigs?.metaData?.brandNames?.nascobal,
    bariactiv: appConfigs?.metaData?.brandNames?.bariactiv,
    nutrition: appConfigs?.metaData?.brandNames?.nutrition,
  };

  const triggerHashChange = () => {
    const currentHash = window?.location?.hash?.substring(
      1,
      window?.location?.hash?.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  useEffect(() => {
    window.addEventListener('hashchange', triggerHashChange, false);
    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });
    return () => {
      window.removeEventListener('hashchange', triggerHashChange, false);
      Subscription && Subscription.remove();
    };
  }, []);

  const pageTitle = 'NUTRITION DIRECT™ | BariActiv® Supplements';
  const pageDescription = 'About BariActiv® vitamins and minerals';

  const renderBariactiv = () => {
    return (
      <div>
        <ContentBlock
          id="bariactiv-block"
          className="no-padding-top margin-offset"
        >
          <Grid
            fluid
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
            }}
          >
            <Row>
              <Col xs={12}>
                <div id="hero" className="hide-in-small">
                  <h2>
                    <span
                      className="brandname-bariactiv txt-uppercase"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.bariactiv,
                      }}
                    />{' '}
                    <br className="hide-only-mobile" />
                    SUPPLEMENTS
                  </h2>
                </div>
                <div id="hero-mobile" className="show-in-small">
                  <img src={NDPatientBariactivHeroMobile} />
                </div>
              </Col>
            </Row>
            <ColorBlock white id="colorblock-bariactiv" className="mb-20">
              <Row>
                <Col xs={12}>
                  <h2 className="strong">
                    About{' '}
                    <span
                      className="brandname-bariactiv"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.bariactiv,
                      }}
                    />{' '}
                    supplements
                  </h2>
                  <p>
                    With{' '}
                    <span
                      className="brandname-nutrition"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.nutrition,
                      }}
                    />
                    , you receive a monthly supply of nutrients that includes{' '}
                    <span
                      className="brandname-nascobal"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.nascobal,
                      }}
                    />{' '}
                    vitamin B<sub>12</sub> Nasal Spray and{' '}
                    <span
                      className="brandname-bariactiv"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.bariactiv,
                      }}
                    />{' '}
                    supplements. Together,{' '}
                    <span
                      className="brandname-nascobal"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.nascobal,
                      }}
                    />{' '}
                    and{' '}
                    <span
                      className="brandname-bariactiv"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.bariactiv,
                      }}
                    />{' '}
                    supplements give you micronutrients recommended by the ASMBS
                    guidelines.*
                  </p>

                  <p className="txt-two-options blue strong">
                    2 great options for getting nutritional supplements you need
                    for lifelong nutritional support after bariatric surgery:
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={2}>
                  <img
                    src={bottle1}
                    alt="bariactiv-bottle tablet-capsule-bariactiv-bottle"
                  />
                </Col>
                <Col xs={12} md={4}>
                  <h2>
                    <span
                      className="brandname-bariactiv"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.bariactiv,
                      }}
                    />{' '}
                    <span className="orange">Chewable:</span>
                  </h2>
                  <ul>
                    <li>
                      Can be started soon after surgery as discussed with your
                      healthcare provider
                    </li>
                    <li>Used for lifelong nutritional support</li>
                    <li>Great-tasting fruit flavor</li>
                  </ul>
                </Col>
                <Col xs={12} md={2}>
                  <img
                    src={bottle2}
                    alt="bariactiv-bottle tablet-capsule-bariactiv-bottle"
                  />
                </Col>
                <Col xs={12} md={4}>
                  <h2>
                    <span
                      className="brandname-bariactiv"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.bariactiv,
                      }}
                    />{' '}
                    <span className="orange">Tablets/Capsules:</span>
                  </h2>
                  <ul>
                    <li>
                      When chewable tablets are no longer required,
                      tablets/capsules can be started as discussed with your
                      healthcare provider
                    </li>
                    <li>Used for lifelong nutritional support</li>
                  </ul>
                </Col>
              </Row>
            </ColorBlock>
            <ColorBlock lightorange id="colorblock-lightorange-table">
              <Row>
                <Col xs={12}>
                  <h2 className="strong">
                    <span
                      className="brandname-nutrition"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.nutrition,
                      }}
                    />
                    : Designed to meet ASMBS Nutritional Guidelines*
                    <sup>&dagger;</sup>
                  </h2>
                  <p>
                    Your monthly delivery from{' '}
                    <span
                      className="brandname-nutrition"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.nutrition,
                      }}
                    />{' '}
                    includes supplements that provide micronutrients the
                    American Society for Metabolic and Bariatric Surgery
                    recommends for people who have had weight loss surgery.
                  </p>
                  <table className="normal">
                    <tbody>
                      <tr
                        style={{
                          backgroundColor: '#005499',
                        }}
                      >
                        <th>
                          <span className="brandname-nutrition orange txt-uppercase strong">
                            ASMBS RECOMMENDED NUTRITIONAL SUPPLEMENTATION*
                          </span>
                        </th>
                        <th>
                          <span className="brandname-nutrition orange txt-uppercase strong">
                            BARIACTIV
                            <sup>&reg;</sup> ONCE-DAILY CHEWABLE MULTIVITAMINS
                          </span>
                        </th>
                      </tr>
                      <tr>
                        <td className="even">
                          Vitamin B<sub>1</sub>
                        </td>
                        <td className="even">
                          <img src={imgTick} className="tick-image" /> 12 mg/day
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Vitamin B<sub>12</sub>
                        </td>
                        <td>
                          <img src={imgTick} className="tick-image" /> 500
                          mcg/day
                        </td>
                      </tr>
                      <tr>
                        <td className="even">Folate</td>
                        <td className="even">
                          <img src={imgTick} className="tick-image" /> 1,360 DFE
                          (800 mcg folic acid)
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Calcium<sup>&#8225;</sup>
                        </td>
                        <td>
                          <img src={imgTick} className="tick-image" /> (see
                          footnote below)
                        </td>
                      </tr>
                      <tr>
                        <td className="even">Vitamin A</td>
                        <td className="even">
                          <img src={imgTick} className="tick-image" /> 3,000
                          mcg/day
                          <sup>&sect;</sup>
                        </td>
                      </tr>
                      <tr>
                        <td>Vitamin E</td>
                        <td>
                          <img src={imgTick} className="tick-image" /> 15 mg/day
                        </td>
                      </tr>
                      <tr>
                        <td className="even">Vitamin K</td>
                        <td className="even">
                          <img src={imgTick} className="tick-image" /> 300
                          mcg/day
                        </td>
                      </tr>
                      <tr>
                        <td>Vitamin D</td>
                        <td>
                          <img src={imgTick} className="tick-image" /> 75
                          mcg/day
                          <sup>&#8741;</sup>
                        </td>
                      </tr>
                      <tr>
                        <td className="even">Iron</td>
                        <td className="even">
                          <img src={imgTick} className="tick-image" /> Chewable:
                          18 mg/day
                          <br />
                          <img src={imgTick} className="tick-image" />{' '}
                          Tablet/Capsule: 45 mg/day
                        </td>
                      </tr>
                      <tr>
                        <td>Zinc</td>
                        <td>
                          <img src={imgTick} className="tick-image" /> 16 mg/day
                        </td>
                      </tr>
                      <tr>
                        <td className="even">Copper</td>
                        <td className="even">
                          <img src={imgTick} className="tick-image" /> 2 mg/day
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <img src={logoAsmbs} alt="asmbs-logo" />
                  <p className="footnote asterik top-spacer">
                    ASMBS Integrated Health Nutritional Guidelines for the
                    Surgical Weight Loss Patient — 2016 Update: Micronutrients.
                  </p>
                  <p className="footnote dagger">
                    For all types of surgery, including Roux-en-Y gastric bypass
                    (RYGB), laparoscopic sleeve gastrectomy (LSG), and
                    laparoscopic adjustable gastric banding (LAGB).
                  </p>
                  <p className="footnote double-dagger">
                    <strong>
                      Calcium is provided in the BariActiv<sup>&reg;</sup>{' '}
                      Calcium (1,200 mg per day) + Vitamin D (3,000 IU per day)
                      supplement. It is very important NOT to take calcium and
                      multivitamin with iron supplements at the same time. Take
                      them at least 2 hours apart.
                    </strong>
                  </p>
                  <p className="footnote section">
                    Equivalent to 10,000 IU/day (3,000 mcg RAE/day) retinol.
                  </p>
                  <p className="footnote double-lines bottom-spacer">
                    Equivalent to 3,000 IU/day.
                  </p>
                </Col>
              </Row>
            </ColorBlock>
            <ColorBlock white id="colorblock-white-icon" className="mb-20">
              <Row>
                <Col xs={12}>
                  <h2 className="strong">How to take your supplements</h2>
                  <p>
                    It’s important to take your nutritional supplements provided
                    by Nutrition Direct
                    <span className="brand-trade-mark">
                      &trade;
                    </span> correctly.{' '}
                    <strong>
                      Important note: The directions are different for chewable
                      supplements and the tablets/capsules.
                    </strong>{' '}
                    Chewable supplements can be chewed without the use of water.
                    Tablets/capsules can be easily swallowed with water.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <img
                    src={imgSmallBottle}
                    style={{ marginBottom: 0 }}
                    alt="patient-nascobal-spray"
                  />
                  <h2>
                    How to use{' '}
                    <span
                      className="brandname-nascobal"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.nascobal,
                      }}
                    />{' '}
                    <br />
                    <span className="orange">Nasal Spray</span>
                  </h2>
                  <p>
                    <span className="prod-name strong">
                      FDA-approved{' '}
                      <span
                        className="brandname-nascobal"
                        dangerouslySetInnerHTML={{
                          __html: brandNames.NASCOBAL,
                        }}
                      />{' '}
                      Nasal Spray
                    </span>
                    <span className="prod-direction">
                      1 spray, 1 nostril, 1x a week
                      <sup className="double-lines">&para;</sup>
                    </span>
                  </p>
                  <AlertBox>
                    Do not drink hot liquids or eat hot foods at least 1 hour
                    before and 1 hour after taking{' '}
                    <span
                      className="brandname-nascobal"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.NASCOBAL,
                      }}
                    />{' '}
                    Nasal Spray.
                    <HangingMary
                      className="hm-double-dagger"
                      symbol="<sup>&para;</sup>"
                      text="Dose adjustments may be required."
                    />
                  </AlertBox>
                </Col>
                <Col xs={12} md={4}>
                  <img
                    src={imgBottle1}
                    style={{ marginBottom: 0 }}
                    alt="bariactiv-battler tablet-capsule-bariactiv-bottle"
                  />
                  <h2>
                    How to take{' '}
                    <span
                      className="brandname-bariactiv"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.bariactiv,
                      }}
                    />
                    <br />
                    <span className="orange">Chewable Supplements:</span>
                  </h2>
                  <p>
                    <span className="prod-name">
                      <span
                        className="brandname-bariactiv"
                        dangerouslySetInnerHTML={{
                          __html: brandNames.bariactiv,
                        }}
                      />{' '}
                      Calcium + D<sub>3</sub> &amp; Magnesium Chewable
                      <sup className="hashish">&#35;</sup>
                    </span>
                    <span className="prod-direction">
                      Take one serving (2 tablets) twice a day
                    </span>
                    <ExternalLink
                      href={NDchewablesPDF}
                      className="prod-link"
                      gaTracking={calciumTracking}
                    >
                      Nutrition Facts
                    </ExternalLink>
                  </p>
                  <p>
                    <span className="prod-name">
                      <span
                        className="brandname-bariactiv"
                        dangerouslySetInnerHTML={{
                          __html: brandNames.bariactiv,
                        }}
                      />{' '}
                      Multivitamin Chewable**
                    </span>
                    <span className="prod-direction">
                      Take one serving (1 tablet) once a day
                    </span>
                    <ExternalLink
                      href={NDchewablesPDF}
                      className="prod-link"
                      gaTracking={multiChewTracking}
                    >
                      Nutrition Facts
                    </ExternalLink>
                  </p>
                  <p>
                    <span className="prod-name">
                      <span
                        className="brandname-bariactiv"
                        dangerouslySetInnerHTML={{
                          __html: brandNames.bariactiv,
                        }}
                      />{' '}
                      Multivitamin + Iron Chewable
                      <sup className="hashish">&#35;</sup>**
                    </span>
                    <span className="prod-direction">
                      Take one serving (1 tablet) once a day
                    </span>
                    <ExternalLink
                      href={NDchewablesPDF}
                      className="prod-link"
                      gaTracking={ironChewTracking}
                    >
                      Nutrition Facts
                    </ExternalLink>
                  </p>
                </Col>
                <Col xs={12} md={4}>
                  <img
                    src={imgBottle2}
                    style={{ marginBottom: 0 }}
                    alt="bariactiv-battler tablet-capsule-bariactiv-bottle"
                  />
                  <h2>
                    How to take{' '}
                    <span
                      className="brandname-bariactiv"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.bariactiv,
                      }}
                    />
                    <br />
                    <span className="orange">Tablet/Capsule Supplements:</span>
                  </h2>
                  <p>
                    <span className="prod-name">
                      <span
                        className="brandname-bariactiv"
                        dangerouslySetInnerHTML={{
                          __html: brandNames.bariactiv,
                        }}
                      />{' '}
                      Calcium + D<sub>3</sub> &amp; Magnesium Tablets
                      <sup className="hashish">&#35;</sup>
                    </span>
                    <span className="prod-direction">
                      Take one serving (2 tablets) twice a day
                    </span>
                    <ExternalLink
                      href={NDfactsPDF1}
                      className="prod-link"
                      gaTracking={calciumChewTracking}
                    >
                      Nutrition Facts
                    </ExternalLink>
                  </p>
                  <p>
                    <span className="prod-name">
                      <span
                        className="brandname-bariactiv"
                        dangerouslySetInnerHTML={{
                          __html: brandNames.bariactiv,
                        }}
                      />{' '}
                      Multivitamin Capsule**
                    </span>
                    <span className="prod-direction">
                      Take one serving (1 capsule) once a day
                    </span>
                    <ExternalLink
                      href={NDfactsPDF1}
                      className="prod-link"
                      gaTracking={multiTabTracking}
                    >
                      Nutrition Facts
                    </ExternalLink>
                  </p>
                  <p>
                    <span className="prod-name">
                      <span
                        className="brandname-bariactiv"
                        dangerouslySetInnerHTML={{
                          __html: brandNames.bariactiv,
                        }}
                      />{' '}
                      Multivitamin + Iron Capsule
                      <sup className="hashish">&#35;</sup>**
                    </span>
                    <span className="prod-direction">
                      Take one serving (1 capsule) once a day
                    </span>
                    <ExternalLink
                      href={NDfactsPDF1}
                      className="prod-link"
                      gaTracking={ironTabTracking}
                    >
                      Nutrition Facts
                    </ExternalLink>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <AlertBox>
                    <HangingMary
                      className=""
                      symbol="<sup>#</sup>"
                      text="It is very important NOT to take calcium and multivitamin with iron supplements at the same time. Take them at least 2 hours apart."
                    />
                    <HangingMary
                      className=""
                      symbol="**"
                      text="Talk to your doctor about which multivitamin option is best for you."
                    />
                  </AlertBox>
                </Col>
              </Row>
              <Row className="pt-20">
                <Col xs={12}>
                  <span className="orange">TIP: </span>
                  <span className="blue" style={{ fontSize: 16 }}>
                    Some medicines and nutritional supplements can affect each
                    other. Tell your healthcare provider about any medicines you
                    are taking, including vitamins and nutritional supplements.
                  </span>
                </Col>
              </Row>
            </ColorBlock>
            <ColorBlock gray>
              <Row>
                <Col xs={12}>
                  <h2 className="strong">
                    Some tips to help you remember to take your vitamins
                  </h2>
                  <p className="mb-10">
                    It can be easy to forget to take your vitamins and minerals.
                    Here are some reminders to help keep you on track:
                  </p>
                  <ul className="list-regular">
                    <li>
                      <span className="strong">Set an alarm.</span> Most phones
                      have them. Be sure to set yours so you will remember when
                      it is time to take your vitamins. If you wear a watch, set
                      the alarm so you will remember to take your vitamins when
                      you are away from the house
                    </li>
                    <li>
                      <span className="strong">
                        Remind yourself with notes, diaries, or checklists.
                      </span>{' '}
                      Post a calendar or a Post-it
                      <sup>&reg;</sup> note where you will see it. You can also
                      make a “to-do” list so you will always remember
                    </li>
                    <li>
                      <span className="strong">
                        Keep the bottles of your nutritional supplements in a
                        place where you will easily see them.
                      </span>{' '}
                      This way, you will remember to take them as your
                      healthcare provider prescribes. Make sure that they are
                      out of the reach of children
                    </li>
                    <li>
                      <span className="strong">Have a support network.</span>{' '}
                      Ask a friend or family member to remind you by phone or
                      text
                    </li>
                  </ul>
                </Col>
              </Row>
            </ColorBlock>
          </Grid>
        </ContentBlock>
        <Row>
          <Col xs={12} md={6}>
            <BrandCTA SpecialistLink href="#" LinkCaption="FIND A PHYSICIAN">
              FIND A PHYSICIAN
            </BrandCTA>
          </Col>
          <Col xs={12} md={6}>
            <BrandCTA
              href="/nutritiondirect/faqs"
              LinkCaption="SEE FREQUENTLY ASKED QUESTIONS"
            >
              SEE FREQUENTLY ASKED QUESTIONS
            </BrandCTA>
          </Col>
        </Row>
      </div>
    );
  };

  const renderPage = () => {
    return (
      <div id="nutrition-patient-bariactiv">
        {renderBariactiv()}
        <ScrollableAnchor id="isi_anchor">
          <div />
        </ScrollableAnchor>
      </div>
    );
  };

  return (
    <Layout
      indication={indication}
      className="nutrition-patient-bariactiv-page"
    >
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default bariactivPage;
