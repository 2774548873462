import React from 'react';
import Link from 'gatsby-link';
import SourceEmitter from '../../libs/emitter';

import { pushEventGA } from '../../libs/utilityHelper';

class ActiveLinkNew extends React.Component {
  handleScrollClick = () => {
    const anchorURL = String(this.anchorURL.href);
    const sectionID = anchorURL.substring(
      anchorURL.indexOf('#') + 1,
      anchorURL.length,
    );
    SourceEmitter.emit('ActiveNavClassTriggerEvent', sectionID);
  };

  handleSpecialistClick = () => {
    SourceEmitter.emit('SpecialistLocatorTrigger', 'open');
  };

  render() {
    const props = this.props;
    // todo: clean this up
    if (props.ScrollLink) {
      return (
        <a
          className={props.className}
          href={this.props.to}
          onClick={this.handleScrollClick}
          ref={_url => {
            this.anchorURL = _url;
          }}
        >
          {props.children}
        </a>
      );
    }

    if (props.SpecialistLink) {
      return (
        <a
          className={props.className}
          href="javascript:void(0);"
          onClick={this.handleSpecialistClick}
        >
          {props.children}
        </a>
      );
    }

    if (!this.props.ExternalLink) {
      return (
        <Link
          to={props.to}
          activeClassName={props.EnableActiveClass ? 'active' : ''}
          onClick={() => pushEventGA(props.EventObject)}
          className={props.className}
        >
          {props.children}
        </Link>
      );
    } else {
      return (
        <a
          href={props.to}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => pushEventGA(props.EventObject)}
          className={props.className}
        >
          {props.children}
        </a>
      );
    }
  }
}

export default ActiveLinkNew;
